import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { TextPortfolioSection } from "../components/TextPortfolioSection";

export const TextPortfolioPageTemplate = ({ spotlight }) => (
  <>
    <TextPortfolioSection {...spotlight} />
  </>
);

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { spotlight } = frontmatter;
  return (
    <Layout
      seoTitle={frontmatter.header_title}
      seoDescription={frontmatter.header_description}
      hideFooter={true}
      pathname={location.href}
    >
      <TextPortfolioPageTemplate spotlight={spotlight} />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query TextPortfolioList {
    markdownRemark(frontmatter: { template: { eq: "text-portfolio-list" } }) {
      frontmatter {
        template
        header_title
        header_description
        spotlight {
          description
          footer
          title
          buttons {
            title
            url
          }
        }
      }
    }
  }
`;
